@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;

    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --secondary-emphasis: 0, 0%, 88%;
    --secondary-hover: 0, 0%, 92%;

    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;

    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --accent-emphasis: 0, 0%, 90%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --destructive-emphasis: 0 72% 51%;

    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;

    --radius: 0.5rem;
    --blue-color: #DBEAFE; 
  }

  .dark {
    /* --background: 0 0% 3.9%; */
    --background: 0 0% 8%;
    /* --background: 0 0% 12%; */
    --foreground: 0 0% 95%;

    /* --card: 0 0% 3.9%; */
    --card: 0 0% 14.9%;
    --card-foreground: 0 0% 98%;

    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;

    --primary: 0 0% 95%;
    --primary-foreground: 0 0% 9%;

    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --secondary-emphasis: 0, 0%, 20%;
    --secondary-hover: 0, 0%, 24%;

    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;

    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --accent-emphasis: 0, 0%, 20%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --destructive-emphasis: 0 72% 51%;

    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --blue-color: #1E3A8A;
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-rgb: 255, 255, 255;
  --font-geist: "Geist", sans-serif;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-rgb: 0, 0, 0;
  }
} */

body {
  font-family: var(--font-geist);
}

.bg-primary-color {
  background-color: rgba(21, 95, 117, 0.05);
}

.border-primary-color {
  border-color: rgba(21, 95, 117, 0.05);
}

.no-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: hsl(var(--primary) / 0.12);
  width: 4px;
  transition: opacity 0.25s ease;
}

:hover::-webkit-scrollbar-thumb {
  visibility: visible;
  background: hsl(var(--primary) / 0.15);
}

::-webkit-scrollbar-track {
  background: hsl(var(--primary) / 0);
}

:hover::-webkit-scrollbar-track {
  background: hsl(var(--primary) / 0.05);
  border-radius: 10px;
}

/* Horizontal Scrollbar Styles */
::-webkit-scrollbar:horizontal {
  height: 12px;
  background: transparent;
}

::-webkit-scrollbar-thumb:horizontal {
  background: hsl(var(--primary) / 0.12);
  height: 4px;
  transition: opacity 0.25s ease;
}

:hover::-webkit-scrollbar-thumb:horizontal {
  visibility: visible;
  background: hsl(var(--primary) / 0.15);
}

::-webkit-scrollbar-track:horizontal {
  background: hsl(var(--primary) / 0);
}

:hover::-webkit-scrollbar-track:horizontal {
  background: hsl(var(--primary) / 0.05);
  border-radius: 0px;
}

/* For Firefox */
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0); /* thumb and track color */
  }

  *:hover {
    scrollbar-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.015); /* thumb and track color on hover */
  }
}
.blue-color-background {
  background-color: var(--blue-color);
}

.p-tree-node-content-clickable {
  padding: 4px;
  color: hsl(var(--primary) / 0.8);
}

.p-tree-node-content-clickable:hover {
  background-color: hsl(var(--muted) / 0.8);
  cursor: pointer;
  border-radius: 4px;
  padding-left: -12px;
}

.p-tree {
  border: none;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
}

.p-treenode {
  padding-top: 0px;
  padding-bottom: 0px;
}

.p-treenode-content {
  color: hsl(var(--primary) / 0.8);
}

.selected-node {
  background-color: hsl(var(--muted));
  border-radius: 4px;
  font-weight: 500;
}

.p-tree-toggler {
  margin-right: 0px;
  width: 1.25rem;
  padding-left: 2px;
  padding-right: 2px;
  color: hsl(var(--muted-foreground) / 0.9);
  border-radius: 4px;
}

.p-tree-toggler:hover {
  background-color: hsl(var(--muted) / 0.8);
  border-width: 1.5px;
  border-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width: 1024px) {
  .p-component {
    font-size: 1rem !important;
  }
}

.p-component {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.p-tree-filter-container .p-tree-filter::placeholder {
  color: #a1a1aa;
  opacity: 1;
}

.p-tree-filter-container .p-tree-filter::-webkit-input-placeholder {
  color: #a1a1aa;
}

.p-tree-filter-container .p-tree-filter::-moz-placeholder {
  color: #a1a1aa;
}

.p-tree-filter-container .p-tree-filter:-ms-input-placeholder {
  color: #a1a1aa;
}

.p-tree-filter-container:hover .p-tree-filter:hover {
  background-color: hsl(var(--muted) / 0.8);
  border-color: hsl(var(--primary) / 0.5);
}

.p-tree-filter-container .p-tree-filter:focus {
  border-radius: 6px;
  box-shadow: inset 0 0 0 2px hsl(var(--primary) / 0.5);
}

.p-inputtext {
  padding-top: 4px;
  padding-bottom: 4px;
  border-color: hsl(var(--primary) / 0.15);
  color: hsl(var(--primary) / 0.8);
}

.p-tree-filter-icon {
  color: hsl(var(--muted-foreground) / 0.8);
}

.p-tree-filter {
  background-color: transparent;
}

.p-tree-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 15;
  background: hsl(var(--background));
}

.truncate-breadcrumb {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.gradient-bar {
  transition: width 0.3s ease;
}

.gradient-bar-notes {
  transition: width 0.3s ease;
  height: 100%;
}

.monaco-editor,
.monaco-editor-background,
.margin {
  background-color: transparent !important;
}

.monaco-editor.showUnused .squiggly-inline-unnecessary {
  opacity: 1 !important; /* Force full opacity */
}

@keyframes rotateWheel {
  to {
    transform: rotate(120deg);
  }
}

#logoDriver:hover #wheel {
  animation: rotateWheel 0.3s ease-in-out forwards;
  transform-origin: center;
  transform-box: fill-box;
}
.animate-spin {
  animation: spin 1s linear infinite; /* Adjust the duration and timing function as needed */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* CSS Animation for Movement and Rotation */
@keyframes moveAndRotate {
  0%,
  100% {
    transform: translateX(-100%) rotate(0deg);
  }
  50% {
    transform: translateX(100%) rotate(360deg); /* Moves right and rotates clockwise */
  }
}

@keyframes moveAndRotateReverse {
  0%,
  100% {
    transform: translateX(0) rotate(0deg);
  }
  50% {
    transform: translateX(-100%) rotate(-360deg); /* Moves left and rotates counter-clockwise */
  }
}

.movingRotatingWheel {
  animation: moveAndRotate 5s linear infinite alternate;
}

#userback_button_container .userback-button {
  z-index: 20 !important;
}

#userback_button_container .userback-button {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.dark #userback_button_container .userback-button {
  background-color: #171717 !important;
  color: #ffffff !important;
}

.z-100 {
  z-index: 92147483644;
}

.z-99 {
  z-index: 2147483645;
}

.uppy-Root {
  color: hsl(var(--primary)) !important;
}

.uppy-DashboardContent-title {
  padding: 0 16px !important;
  white-space: nowrap !important;
  overflow: auto !important;
  font-style: italic;
}

.dark .uppy-Dashboard-inner {
  background-color: hsl(var(--primary-foreground)) !important;
  border-color: hsl(var(--border)) !important;
}

.uppy-Dashboard-Item {
  border-color: hsl(var(--border)) !important;
}

.uppy-Dashboard-Item-status {
  color: hsl(var(--muted-foreground)) !important;
}

.uppy-StatusBar {
  background-color: hsl(var(--primary-foreground)) !important;
  border-top-color: hsl(var(--border)) !important;
}

.uppy-StatusBar-content {
  color: hsl(var(--primary)) !important;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: hsl(var(--primary)) !important;
  color: hsl(var(--primary-foreground)) !important;
}

.uppy-StatusBar-actions {
  background-color: hsl(var(--primary-foreground)) !important;
  border-color: hsl(var(--border)) !important;
}

.uppy-DashboardContent-bar {
  background-color: hsl(var(--primary-foreground)) !important;
  border-color: hsl(var(--border)) !important;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: hsl(var(--primary) / 0.8) !important;
}

.uppy-StatusBar-actionBtn {
  color: #18181b !important;
  background-color: #f4f4f5 !important;
}

.uppy-StatusBar-actionBtn:hover {
  background-color: #e4e4e7 !important;
}

.uppy-DashboardContent-back {
  color: hsl(var(--destructive-emphasis)) !important;
  background-color: #18181b00 !important;
}

.uppy-DashboardContent-back:hover {
  background-color: hsl(var(--primary) / 0.05) !important;
}

.uppy-Dashboard-Item-previewInnerWrap {
  background-color: hsl(var(--input)) !important;
}

.uppy-Dashboard-Item-previewIcon svg {
  filter: brightness(0%);
}

.uppy-Dashboard-Item-action--remove {
  color: hsl(var(--destructive-emphasis)) !important;
}

.uppy-Dashboard-Item-action--remove:hover {
  color: hsl(var(--destructive)) !important;
}

.uppy-u-reset.uppy-StatusBar-actionBtn--done {
  display: none !important;
}

.uppy-Dashboard-AddFiles-title {
  color: hsl(var(--foreground)) !important;
}

.uppy-Dashboard-AddFiles {
  border-color: hsl(var(--border)) !important;
  background-color: hsl(var(--primary-foreground)) !important;
}

.uppy-Dashboard-AddFilesPanel {
  background-image: linear-gradient(
    0deg,
    hsl(var(--background) / 0.35) 35%,
    hsl(var(--background) / 1) 100%
  ) !important;
}

.editor-underline {
  text-decoration-thickness: 0.5px;
  text-underline-offset: 4px;
}

.editor-link {
  color: #71717a;
  text-decoration: underline;
  cursor: pointer;
  text-decoration-thickness: 0.5px;
  text-underline-offset: 4px;
  font-weight: 400;
}

.editor-link:hover {
  color: #3f3f46;
  background-color: #f4f4f5;
  padding: 2px 0;
  border-radius: 2px;
}

.dark .prose,
.dark .prose :is(h1, h2, h3, h4, h5, h6, strong, blockquote) {
  color: hsl(var(--primary) / 0.8) !important;
}
.prose h1 {
  font-size: 1.875rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.prose h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.prose h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.prose h1 strong,
.prose h2 strong,
.prose h3 strong {
  font-weight: 600;
}


.prose p {
  margin-top: 0.15rem;
  margin-bottom: 0.75rem;
}

.prose li > p {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.prose ul,
.prose ol {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.prose hr {
  border-top-color: hsl(var(--primary) / 0.2);
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.prose :not(pre) > code {
  color: hsl(var(--primary) / 0.8) !important;
  background-color: hsl(var(--muted)) !important;
  border-color: hsl(var(--border)) !important;
}

.selection {
  @apply inline;
}

.selection,
*::selection {
  background-color: #1e40af2e;
}

.dark .selection,
.dark *::selection {
  background-color: #5e68d04d;
}

.saving-indicator {
  position: fixed;
  top: 64px;
  right: 32px;
  background-color: hsl(var(--card));
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  color: hsl(var(--primary) / 0.9);
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid hsl(var(--border));
  z-index: 1000;
  font-size: 14px;
}

@media (max-width: 1023px) {
  .ProseMirror {
    padding: 0rem;
  }
}

@media (max-width: 640px) {
  .ProseMirror {
    padding: 0rem;
  }
}

.ProseMirror > * {
  max-width: 42rem;
  margin: 0 auto;
}

.tiptap > * + * {
  margin-top: 0.75em;
}

.tiptap pre {
  background: rgba(24, 24, 27);
  color: hsl(0, 0%, 90%);
  font-family: "GeistMono", monospace;
  padding: 1.25rem 1rem;
}

.tiptap pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

.tiptap pre .hljs-comment,
.tiptap pre .hljs-quote {
  color: #616161;
}

.tiptap pre .hljs-variable,
.tiptap pre .hljs-template-variable,
.tiptap pre .hljs-attribute,
.tiptap pre .hljs-tag,
.tiptap pre .hljs-name,
.tiptap pre .hljs-regexp,
.tiptap pre .hljs-link,
.tiptap pre .hljs-selector-id,
.tiptap pre .hljs-selector-class {
  color: #f98181;
}

.tiptap pre .hljs-number,
.tiptap pre .hljs-meta,
.tiptap pre .hljs-built_in,
.tiptap pre .hljs-builtin-name,
.tiptap pre .hljs-literal,
.tiptap pre .hljs-type,
.tiptap pre .hljs-params {
  color: #fbbc88;
}

.tiptap pre .hljs-string,
.tiptap pre .hljs-symbol,
.tiptap pre .hljs-bullet {
  color: #b9f18d;
}

.tiptap pre .hljs-title,
.tiptap pre .hljs-section {
  color: #faf594;
}

.tiptap pre .hljs-keyword,
.tiptap pre .hljs-selector-tag {
  color: #70cff8;
}

.tiptap pre .hljs-emphasis {
  font-style: italic;
}

.tiptap pre .hljs-strong {
  font-weight: 700;
}

.tiptap blockquote {
  border-left-color: hsl(var(--primary)) !important;
  border-left-width: 3px;
  padding-left: 1rem;
  font-style: normal;
  font-weight: 400;
}

.prose blockquote {
  quotes: none;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.code-block {
  position: relative;
}

.tiptap table {
  border-collapse: collapse;
  margin: auto;
  overflow: visible;
  table-layout: fixed;
  width: 100%;
}

.tiptap table td,
.tiptap table th {
  border: 1px solid hsl(var(--primary) / 0.2) !important;
  box-sizing: border-box;
  min-width: 1em;
  padding: 8px 8px;
  position: relative;
  vertical-align: top;
}

.tiptap table td > *,
.tiptap table th > * {
  margin-bottom: 0;
}

.tiptap table th {
  border: 1px solid hsl(var(--primary) / 0.2) !important;
  font-weight: 600;
  text-align: left;
}

.tiptap table .selectedCell:after {
  background: hsl(var(--primary) / 0.1);
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.tiptap table .column-resize-handle {
  background-color: #adf;
  bottom: -2px;
  position: absolute;
  right: -2px;
  pointer-events: none;
  top: 0;
  width: 4px;
}

.tiptap table p {
  margin: 0;
}

.tiptap p.is-empty::before {
  color: hsl(var(--muted-foreground) / 0.7);
  font-weight: 300;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: visible;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}

ul[data-type="taskList"] p {
  margin: 0;
}

ul[data-type="taskList"] li {
  display: flex;
}

ul[data-type="taskList"] li > label {
  flex: 0 0 auto;
  margin-top: -1.5px;
  margin-right: 0.75rem;
  user-select: none;
}

ul[data-type="taskList"] li > div {
  flex: 1 1 auto;
  margin-bottom: 0;
}

ul[data-type="taskList"] li ul li,
ul[data-type="taskList"] li ol li {
  display: list-item;
}

ul[data-type="taskList"] > li {
  display: flex;
}

input[type="checkbox"] {
  cursor: pointer;
  border-radius: 2px;
  color: hsl(var(--primary));
}

input[type="checkbox"]:hover {
  color: hsl(var(--primary) / 0.8);
}

input[type="checkbox"]:focus {
  box-shadow: 0 0 0 0;
  color: hsl(var(--primary) / 0.6);
}

input[type="checkbox"]:not(:checked) {
  background-color: hsl(var(--background));
}

input[type="checkbox"]:not(:checked):hover {
  background-color: hsl(var(--primary) / 0.1);
}

input[type="checkbox"]:not(:checked):focus {
  background-color: hsl(var(--primary) / 0.6);
}

input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.dark input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='black' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

.grip-column,
.grip-row {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: hsl(var(--primary) / 0.1);
  cursor: pointer;
  position: absolute;
  z-index: 10;
  overflow: visible !important;
}

.grip-column {
  width: calc(100% + 1px);
  border-left: 1px solid hsl(var(--primary) / 0.2);
  height: 12px;
  left: 0;
  margin-left: -1px;
  top: -12px;
}

.grip-column:hover,
.grip-column.selected {
  background-color: hsl(var(--primary) / 0.15);
}

.grip-column:hover::before,
.grip-column.selected::before {
  content: "";
  width: 10.5px;
  border-bottom: 2px dotted hsl(var(--primary) / 0.6);
}

.grip-column.first {
  border-left: 1px solid rgba(0, 0, 0, 0) !important;
  border-top-left-radius: 4px;
}

.grip-column.last {
  border-top-right-radius: 4px;
}

.grip-column.selected {
  background-color: hsl(var(--primary) / 0.3);
  border-color: hsl(var(--primary) / 0.3);
  box-shadow: 0 0 4px hsl(var(--primary) / 0.1);
}

.grip-row {
  height: calc(100% + 1px);
  border-top: 1px solid hsl(var(--primary) / 0.2);
  width: 12px;
  left: -12px;
  top: 0;
  margin-top: -1px;
}

.grip-row:hover,
.grip-row.selected {
  background-color: hsl(var(--primary) / 0.1);
}

.grip-row:hover::before,
.grip-row.selected::before {
  content: "";
  height: 10px;
  border-left: 2px dotted hsl(var(--primary) / 0.6);
}

.grip-row.first {
  border-top: 1px solid rgba(0, 0, 0, 0) !important;
  border-top-left-radius: 4px;
}

.grip-row.last {
  border-bottom-left-radius: 4px;
}

.grip-row.selected {
  background-color: hsl(var(--primary) / 0.3);
  border-color: hsl(var(--primary) / 0.3);
  box-shadow: 0 0 4px hsl(var(--primary) / 0.1);
}

.sidebar-padding {
  padding: 0.5rem;
}

@media (min-width: 1024px) {
  .sidebar-padding {
    padding-left: 18rem;
  }
}

.sidebar-main-content-padding {
  padding: 0.5rem;
}

@media (min-width: 768px) {
  .sidebar-main-content-padding {
    padding: 1rem;
  }
}

@media (min-width: 1024px) {
  .sidebar-main-content-padding {
    padding: 2rem 1rem;
  }
}

@media (min-width: 1280px) {
  .sidebar-main-content-padding {
    padding: 3rem;
  }
}

.border-gradient {
  position: relative;
  border: 2px solid transparent;
  border-radius: 6px;
  background-clip: padding-box;
  padding: 10px;
}

.border-gradient:not(:disabled)::after {
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: linear-gradient(to right, #6366f1, #a855f7);
  content: "";
  z-index: -1;
  border-radius: 6px;
}

.node-codeBlock pre::selection,
.node-codeBlock pre *::selection {
  background: rgba(255, 255, 255, 0.2);
  color: #ffffff;
}
